export default {
    name: 'show-more',
    props: {
        item: {
            type: Object,
            default: {}
        },
        close: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {}
    },
    watch: {},

    components: {},

    created() {},

    methods: {
        closePopUp() {
            this.$emit('close-popup');
        }
    }
}
