import {mapActions, mapGetters, mapMutations} from 'vuex';
import {validationMixin} from "vuelidate";
import VueGallery from 'vue-gallery';
import cardSlider from "./components/slider/index.vue"
import customBreadcrumbs from '@/components/custom-breadcrumbs/index.vue'
import timeItem from '@/modules/card/components/time-item/index.vue'
import showMore from "@/modules/card/components/show-more/index.vue";

export default {
  name: 'card',
  mixin: [validationMixin],


  data() {
    return {
      showMore: false,
      promotionTimeEnded: false,
      startTime: '',
      endTime: '',
      times: [
        {id: 0, text: this.$t('days'), time: 0},
        {id: 1, text: ":", time: 1},
        {id: 2, text: ":", time: 1},
        {id: 3, text: ":", time: 1}
      ],

      progress: 100,
      // isActive: false,
      timeinterval: undefined,
      like: false,
      commentSkip: 4,
      questionSkip: 4,
      mainImg: '',

      selectedWeight: null,
      selectCount: 1,

      activeImg: {id: 0, img: require('../../assets/img/card/upload0.jpg')},
      activeItem: 'description',
      productAmount: 1,
      testImg: [
        require('../../assets/img/card/upload0.jpg'),
        require('../../assets/img/card/upload1.jpg'),
        require('../../assets/img/card/upload2.png'),

      ],
      index: null,
      sale: null,
      payload: {
        name: '',
        comment: '',
        rating: 0,
        product_id: null,
        date: null
      },
      params: {
        title: '',
        description: '',
        keywords: '',
        name: ''
      },
    }
  },
  watch: {
    '$route.path'() {
      this.fetchProduct({slug: this.$route.params.item}).then(() => {
        // this.product.title = this.getProduct.title

        // this.fetchBreadcrumbs({
        //     'slug': this.getProduct.slug,
        //     'id': this.getProduct.id
        // })
      });
    },

  },

  components: {
    'gallery': VueGallery,
    cardSlider,
    customBreadcrumbs,
    timeItem,
    showMore
  },

  created() {
  },
  mounted() {

    let lang = this.$route.params.lang
    console.log(this.times[0].time);
    if (this.times[0].time === 0) {
      this.times[0].text = this.$t('days')
      this.times[1].text = this.$t('hours')
      this.times[2].text = this.$t('minutes')
      this.times[3].text = this.$t('seconds')
    }
    switch (this.times[0].time) {
      case 1 || 31:
        this.times[0].text = 'день'
        break;
      case lang === 'ru' && this.times[0].time !== 1 && this.times[0].time !== 31:
        this.times[0].text = 'дней'
        break;
      case lang === 'ua' && this.times[0].time !== 1 && this.times[0].time !== 31 :
        this.times[0].text = 'днів'
        break;
      case lang === 'ua' && this.times[0].time >= 2 && this.times[0].time <= 24:
        this.times[0].text = 'дні'
        break;
      case lang === 'ru' && this.times[0].time >= 2 && this.times[0].time <= 24:
        this.times[0].text = 'дня'
        break;
      default:
        this.times[0].text = 'день'
    }


    let id = null
    if (this.$route.params.id !== undefined) {
      id = this.$route.params.id

    }
    this.fetchProduct({slug: this.$route.params.item, id: id}).then(() => {

      this.selectedWeight = this.getProduct.weights[0]

      this.testImg[0] = this.getProduct.image

      this.startTime = this.getProduct.weights[0].sale.date_from
      this.endTime = this.getProduct.weights[0].sale.date_to
    }).then(() => {
      this.updateTimer();
      this.timeinterval = setInterval(this.updateTimer, 1000);
    })


  },
  computed: {
    ...mapGetters({
      getProduct: 'card/product',
      listProduct: 'home/list',
      listProductLength: 'home/listLength',
      listProductLoading: 'home/listLoading',
      showFastBuy: 'card/popupFastBuy',
      showPopupComment: `card/popupComment`,
      showPopupQuestion: 'card/popupQuestion',
      productLoading: 'card/productLoading',
      wishList: 'favorites/whichList',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    comments() {
      let arr = []
      for (let i in this.getProduct.comments) {
        if (arr.length === this.commentSkip || this.getProduct.comments[i] === undefined) {
          break
        }
        arr.push(this.getProduct.comments[i])
      }
      return arr
    },
    questions() {
      let arr = []
      for (let i in this.getProduct.questions) {
        if (arr.length === this.questionSkip || this.getProduct.questions[i] === undefined) {
          break
        }
        arr.push(this.getProduct.questions[i])
      }
      return arr
    },
    product() {
      if (this.isAuthenticated) {
        if (this.getProduct) {
          if (!this.wishList) return []
          for (let i in this.wishList) {
            if (this.getProduct.id === this.wishList[i].id) {
              this.getProduct.selected = true
              this.like = true

            }
          }
          return this.getProduct
        }
      } else {
        return this.getProduct
      }
    }
  },
  methods: {
    ...mapActions({
      fetchProduct: `card/GET_PRODUCT`,
      addFavourite: `favorites/ADD_PRODUCT_TO_FAVORITE`,
      removeItemFromFavourites: `favorites/REMOVE_PRODUCT_IN_FAVORITE`,
      fetchFavoriteList: `favorites/GET_FAVORITES_LIST`
    }),

    ...mapMutations({
      changePopupComment: `card/changePopupComment`,
      changePopupFastBuy: 'card/CHANGE_POPUP_FAST_BUY',
      changePopupQuestion: 'card/CHANGE_POPUP_QUESTION',
      addBasket: 'basket/ADD_ITEM_TO_BASKET',
      changeShowPopupAddBasket: 'basket/CHANGE_POPUP_ADD_BASKET',
      changePopupRegistration: 'card/CHANGE_POPUP_REGISTRATION',
      changeShowPromotion: 'card/CHANGE_POPUP_PROMOTION'

    }),
    openShowMore() {
      this.showMore = !this.showMore
    },

    updateTimer: function () {
      if (
        this.times[3].time > 0 ||
        this.times[2].time > 0 ||
        this.times[1].time > 0 ||
        this.times[0].time > 0
      ) {
        this.getTimeRemaining();
        this.updateProgressBar();
      } else {
        clearTimeout(this.timeinterval);
        this.promotionTimeEnded = true
        // this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
        this.progress = 0;
      }
    },
    getTimeRemaining: function () {
      let t = Date.parse(new Date(this.endTime)) - Date.parse(new Date());
      if (t >= 0) {
        this.times[3].time = Math.floor(t / 1000 % 60); //seconds
        this.times[2].time = Math.floor(t / 1000 / 60 % 60); //minutes
        this.times[1].time = Math.floor(t / (1000 * 60 * 60) % 24); //hours
        this.times[0].time = Math.floor(t / (1000 * 60 * 60 * 24)); //days
      } else {
        this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
        this.progress = 0;
      }
    },
    updateProgressBar: function () {
      let startTime = Date.parse(new Date(this.startTime));
      let currentTime = Date.parse(new Date());
      let endTime = Date.parse(new Date(this.endTime));
      let interval = parseFloat(
        (currentTime - startTime) / (endTime - startTime) * 100
      ).toFixed(2);
      this.progress = 100 - interval;
    },
    endPromotion() {
      console.log('actions is and')
    },
    changeCommentSkip() {
      this.commentSkip += this.commentSkip
    },
    changeQuestionSkip() {
      this.questionSkip += this.questionSkip
    },
    setVolume(vol) {
      let type;
      switch (vol.type) {
        case 'миллилитры':
          type = 'мл'
          break;
        case 'мілілітри':
          type = 'мл'
          break;
        case 'milliliters':
          type = 'ml'
          break;
        case 'граммы':
          type = 'гр'
          break;
        case 'гр':
          type = 'гр'
          break;
        case 'грами':
          type = 'гр'
          break;
        case 'grams':
          type = 'grams'
          break;
        case 'мл':
          type = 'мл'
          break;
      }
      return type
    },
    deleteFavorite(product) {
      let obj = {}
      obj.product_id = product

      this.removeItemFromFavourites(obj).then(() => {
        this.$toasted.success(this.$t('successDeletedFavourites'))
        this.like = false
        this.fetchFavoriteList();
      }).catch(error => {
        console.log(error);
        if (error.response.status === 422) {

        }
      });
    },
    addFavorite(product) {
      let obj = {}
      obj.product_id = product
      if (this.isAuthenticated) {
        this.addFavourite(this.product).then(() => {
          this.fetchFavoriteList()
          this.$toasted.success(this.$t('successAddFavourites'));
          this.like = true
        }).catch(error => {
          console.log(error);
          if (error.response.status === 422) {

          }
        });
      } else {
        this.changePopupRegistration(true);
      }
    },

    changeCount(type, count = 1) {
      if (type === 'increment') {
        console.log(1);
        this.selectCount++
      } else  {
        console.log(2);
        if (count) {
          console.log(4);
          this.selectCount--
        }

      }
    },
    setImg(img) {
      this.activeImg = img
    },
    setActive(menuItem) {
      this.activeItem = menuItem
    },

    addInCard(product) {
      this.addBasket({
        select_count: this.selectCount,
        select_price: this.selectedWeight.price,
        select_volume: this.selectedWeight,
        ...product
      })
      console.log(this.selectCount)
      this.$toasted.success(this.$t('successAddToCard'));
    },
  },
}
