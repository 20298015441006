export default {
    name: 'timeItem',

    props: ["time"],

    data() {
        return {}
    },

}
